import React, { useState } from 'react';
import CharteGraphique from './CommandeEnLigneTabs/CharteGraphique';
import ModeDePaiement from './CommandeEnLigneTabs/ModeDePaiement';
import ModeLivraison from './CommandeEnLigneTabs/ModeLivraison';
import PlageHoraire from './CommandeEnLigneTabs/PlageHoraire';

export default function CommandeEnLigneEntreprise() {
  const [activeTab, setActiveTab] = useState('Charte Graphique');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Charte Graphique':
        return <CharteGraphique />;
      // case 'Mode de paiement':
      //   return <ModeDePaiement />;
      // case 'Plage horaire':
      //   return <PlageHoraire />;
      // case 'Mode de livraison':
      //   return <ModeLivraison />;
      default:
        return null;
    }
  };

  const renderTabContentHeaderText = () => {
    switch (activeTab) {
      case 'Charte Graphique':
        return 'Charte Graphique';
      // case 'Mode de paiement':
        // return 'Mode de paiement';
      // case 'Plage horaire':
        // return 'Plage horaire';
      // case 'Mode de livraison':
        // return 'Mode de livraison';
      default:
        return null;
    }
  };

  return (
    <div className="CommandeEnLigne h-full">
      <div className="section-wrapper fl_row gp20 h-full">
        <div className="tabs-list">
          <ul className="fl_col gp8">
            {[
              // 'Mode de paiement',
              // 'Plage horaire',
              // 'Mode de livraison',
              'Charte Graphique',
            ].map((tab) => (
              <li
                key={tab}
                className={tab === activeTab ? 'active-tab' : ''}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        <div className="tabs-content fl-1 pd12 rd8 AliceBlue-bg">
          <div className="content-wrapper fl_col gp20">
            <div className="header-tab rd8">
              <p className="p18 w-700 white-txt">
                {renderTabContentHeaderText()}
              </p>
            </div>
            <div className="pd20-r-l h-full">{renderTabContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
