import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Statistique() {
  const authUser = useSelector((state: any) => state.auth?.body);



  const injectHtml = () => {
    const iframe = document.getElementById('frame') as HTMLIFrameElement;

    // Ensure iframe is loaded and has contentWindow
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const iframeDoc = iframe.contentWindow.document;

      // Get the username and password fields
      const usernameField = iframeDoc.getElementById(
        'Username',
      ) as HTMLInputElement;
      const passwordField = iframeDoc.getElementById(
        'Password',
      ) as HTMLInputElement;

      // Get the submit button
      const submitButton = iframeDoc.querySelector(
        "button[type='submit']",
      ) as HTMLButtonElement;

      // Get the login container and form
      const containerLogin = iframeDoc.querySelector(
        '.col-sm-12',
      ) as HTMLDivElement;

      // Fill in the login credentials if the fields are found
      if (usernameField && passwordField) {
        usernameField.value = authUser?.email;
        passwordField.value = authUser?.password;
      }

      // Hide the login container if it exists
      if (containerLogin) {
        containerLogin.style.display = 'none';
      }

      // Create and style the loading content
      const loadingContent = document.createElement('div');
      loadingContent.innerHTML = '<p>Chargement...</p>';
      loadingContent.style.position = 'relative';
      loadingContent.style.top = '50%';
      loadingContent.style.left = '50%';
      loadingContent.style.color = '#2A66C6';
      loadingContent.style.transform = 'translate(-50%, -50%)';
      loadingContent.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
      loadingContent.style.padding = '20px';
      loadingContent.style.borderRadius = '10px';

      // Append the loading content to the document body
      document.body.appendChild(loadingContent);

      // Submit the form if the submit button exists
      if (submitButton) {
        submitButton.click();
      } 
      // Cleanup function to remove loading content after unmount
      return () => {
        if (loadingContent) {
          document.body.removeChild(loadingContent);
        }
      };
    } 
  };

  useEffect(() => {
    // Use setTimeout to allow iframe to load its content before injecting HTML
    const timer = setTimeout(injectHtml, 1000);

    // Cleanup the timer on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <></>
    // <div>
    //   <iframe
    //     id="frame"
    //     src="https://dev.statistiques.frenchinnov.fr/"
    //     style={{ width: '100%', height: '100vh', border: 'none' }}
    //     title="External Site"
    //   />
    // </div>
  );
}
