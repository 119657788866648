import React, { ReactNode } from 'react';

/**
 * ToggleSelect component description.
 *
 * @ToggleSelect
 *
 */

interface ToggleSelectProps {
  children: ReactNode;
  className?: string;
}

const ToggleSelect: React.FC<ToggleSelectProps> = ({ children, className }) => {
  return (
    <div className={`ToggleSelect ${className}`}>
      <div className="toggle-wrapper fl_row fl_wrap">{children}</div>
    </div>
  );
};

export default ToggleSelect;
