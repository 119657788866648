import React, { ReactNode } from 'react';

/**
 * TableContent component description.
 *
 * @TableContent
 *
 */

interface TableContentProps {
  children: ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>; // for ref on parent component
}

const TableContent: React.FC<TableContentProps> = ({
  children,
  className,
  ref,
}) => {
  return (
    <div className={`TableContent ${className ? className : ''}`} ref={ref}>
      {children}
    </div>
  );
};

export default TableContent;
