export function convertDate(dateString: string) {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
