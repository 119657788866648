import React from 'react';
import HeaderProfileInfo from '../../../components/ProfileInfo/HeaderCompany';
import TabSwitcher from './TabSwitcherCompany';
import CardContainer from '../../CardContainer';
import ButtonElement from '../../../components/button/button';
import FicheTab from './FicheTab';
import InformationFinanciere from './InformationFinanciere';
import { languageData } from '../../../i18n';
import { useSelector, useDispatch } from 'react-redux';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import Toast from '../../../components/Toast/Toast';
import ListeDesEtablissements from './ListeDesEtablissement';
import CatalogueTabContent from './CatalogueTabContent';
import TableHeader from '../../../components/Table/TableHeader';
import UserRolesContainer from '../../Roles/UserRolesContainer';
import UsersContainer from '../../Users/UsersContainer';
import useHandleSubmit from './useHandleSubmit';
import InputField from '../../../components/Input/Input';
import {
  activateDeactivateCampanyRequest,
  getAllEntreprise,
  getCompanyByIdInfos,
} from '../../../store/actions/actions';
import CommandeEnLigneEntreprise from './CommandeEnLigneEntreprise';

interface EntrepriseprofileInfoProps {
  id: number;
  activeMiniTab: string;
  setActiveMiniTab: React.Dispatch<React.SetStateAction<string>>;
}

export default function EntrepriseProfileInfoContainer(
  props: EntrepriseprofileInfoProps,
) {
  const handleSubmit = useHandleSubmit(props.activeMiniTab);
  const dispatch = useDispatch();
  const [paginationModel] =
    React.useState<servicePaginationModels.PaginationModelType>({
      paginationModel: {
        page: 1,
        pageSize: 500,
        nbPage: 0,
      },
    });
  const fetchCompaniesList = React.useCallback(
    (paginationModel: servicePaginationModels.PaginationModelType) => {
      dispatch(getAllEntreprise(paginationModel));
    },
    [],
  );

  const editResponse = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editResponse,
  );
  const claimsEntreprise = useSelector(
    (state: any) => state.auth.claimsEntreprise,
  );
  const claimsEtablissement = useSelector(
    (state: any) => state.auth.claimsEtablissement,
  );
  const companySheet = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.companySheet,
  );

  const companyInfos = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.companyInfos,
  );

  const ActivateDisactivateCampany = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedActiveStatus = event.target.checked;
    dispatch(
      activateDeactivateCampanyRequest(
        companySheet?.id,
        updatedActiveStatus,
        () => {
          dispatch(getCompanyByIdInfos({ id: props.id }));
          fetchCompaniesList(paginationModel);
        },
      ),
    );
  };

  const checkClaims = () => {
    if (!claimsEntreprise?.includes('Géneral.Affichage')) {
      return false;
    }
    if (!claimsEntreprise?.includes('Géneral.Modification')) {
      return false;
    }
    return true;
  };
  const isClaimsValid = checkClaims();

  const renderActiveTabContent = () => {
    switch (props.activeMiniTab) {
      case '1':
        return (
          <>
            {claimsEntreprise?.includes('Fiche.Affichage') && (
              <>
                <TableHeader
                  type="save"
                  onClickSave={handleSubmit}
                  textAdd={languageData.global.add}
                  isClaimsValid={isClaimsValid}
                  activeMiniTab={props.activeMiniTab}
                  fichShow={claimsEntreprise?.includes('Fiche.Affichage')}
                />
                <FicheTab
                  id={props.id}
                  isClaimsValid={isClaimsValid}
                  fichModifier={claimsEntreprise?.includes(
                    'Fiche.Modification',
                  )}
                />
              </>
            )}
          </>
        );
      case '2':
        return (
          <>
            <CardContainer
              type="standard"
              ClassName="save-settings fl_row jc_fe"
            >
              {isClaimsValid &&
                claimsEntreprise?.includes(
                  'Information financière.Affichage',
                ) && (
                  <ButtonElement
                    buttonStyle="btn-primary"
                    type={'TextButton'}
                    text={languageData.global.Modal.save}
                    buttonSize={'large'}
                    onClick={handleSubmit}
                  />
                )}
            </CardContainer>
            {claimsEntreprise?.includes('Information financière.Affichage') && (
              <InformationFinanciere
                id={props.id}
                isClaimsValid={isClaimsValid}
                InformationFinancièreModifier={claimsEntreprise?.includes(
                  'Information financière.Modification',
                )}
              />
            )}
          </>
        );
      case '4':
        return claimsEtablissement?.includes('Géneral.Affichage') ? (
          <ListeDesEtablissements
            companyId={props.id}
            claimEtablissementAjout={claimsEtablissement?.includes(
              'Géneral.Ajout',
            )}
            claimEtablissementModification={claimsEtablissement?.includes(
              'Géneral.Modification',
            )}
          />
        ) : null;
      case '5':
        return <UserRolesContainer companyId={props.id} />;
      case '6':
        return <UsersContainer />;
      case '7':
        return (
          <div>
            {/* <CardContainer type="standard"> */}
            <CatalogueTabContent
              companyId={props.id}
              companyName={' ' + companySheet.commercial_Name}
            />
            {/* </CardContainer> */}
          </div>
        );
      case '8':
        return (
          <CardContainer type="standard" fullHeight>
            <CommandeEnLigneEntreprise />
          </CardContainer>
        );
      default:
        return null;
    }
  };

  return (
    <div className="EntrepriseProfileInfoContainer ProfileInfo fl_col gp10 h-full">
      <CardContainer ClassName="HeaderProfileInfoContainer" type="standard">
        <div className="profile-wrapper fl_col gp32">
          <HeaderProfileInfo />
        </div>
        <div className="toggle-action">
          <InputField
            style="switcher"
            label={
              companyInfos?.status?.name == 'active'
                ? languageData.global.status.actif
                : languageData.global.status.deactivated
            }
            name="active"
            checked={
              companyInfos?.status && companyInfos?.status.name == 'active'
                ? true
                : false
            }
            onChange={ActivateDisactivateCampany}
          />
        </div>
      </CardContainer>

      <CardContainer ClassName="HeaderTabSwitcherContainer" type="standard">
        <TabSwitcher
          setActiveMiniTab={props.setActiveMiniTab}
          activeMiniTab={props.activeMiniTab}
          showFiche={claimsEntreprise?.includes('Fiche.Affichage')}
          showInfoFinanciere={claimsEntreprise?.includes(
            'Information financière.Affichage',
          )}
        />
      </CardContainer>

      {renderActiveTabContent()}

      <Toast
        type={editResponse === true ? 'success' : 'error'}
        description={editResponse ? 'success' : 'error'}
        isVisible={editResponse ?? false}
        timeout={4000}
      />
    </div>
  );
}
