import React, { useEffect, useState } from 'react';
import TableHeader from '../../components/Table/TableHeader';
import { languageData } from '../../i18n';
import CardContainer from '../CardContainer';
import EditUserProfilePassword from './EditUserProfilePassword';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import Modal from '../../components/Modal/Modal';
import {
  updateUserPassworRequest,
  updateUserRequest,
} from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import ProfileUserUpdate from './ProfileUserUpdate';
import { useSelector } from 'react-redux';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import Toast from '../../components/Toast/Toast';
interface IupdateUser {
  lastName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
}
interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
  rewritePassword?: string;
}
interface Passwords {
  newPassword: string;
}
export default function EditMyProfile() {
  const [modalShowEditProfile, SetmodalShowEditProfile] = useState(false);
  const [userProfile, setUserProfile] = useState<Partial<IupdateUser>>({});
  const [confirm, setConfirm] = useState<boolean>(false);
  const [passwords, setPasswords] = useState<Partial<IUpdatePassword>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorsOldPassword, setErrorsOldPassword] = useState(false);

  const [ErrorMessage, setErrorMessage] = useState({
    oldPass: {
      hasError: false,
      hasMessage: '',
    },
    newPass: {
      hasError: false,
      hasMessage: '',
    },
    rewritePassword: {
      hasError: false,
      hasMessage: '',
    },
  });

  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: { auth: serviceAuthModels.authState }) => state.auth.user,
  );

  const { error, loading } = useSelector((state: any) => state.updatedPassword);

  const resetPasswordSucces = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.resetPasswordSucces,
  );

  const payload = {
    lastName: userProfile.lastName,
    email: userProfile.email,
    firstName: userProfile.firstName,
    phoneNumber: userProfile.phoneNumber,
  };

  const passworPayload = {
    oldPassword: passwords.oldPassword || '',
    password: passwords.newPassword || '',
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  useEffect(() => {
    setUserProfile({
      lastName: userDetails.lastName,
      email: userDetails.email,
      firstName: userDetails.firstName,
      phoneNumber: userDetails.phoneNumber,
    });
  }, [dispatch, userDetails]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const debounce = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(debounce);
  }, [isLoading]);

  const handleUpdateUser = () => {
    dispatch(updateUserRequest(payload));
    setIsLoading(true);
  };

  //ALERT : under dev Dont Make review on this part
  // const handleValidation = () => {
  //   const { oldPassword, newPassword, rewritePassword }: IUpdatePassword =
  //     passwords as IUpdatePassword;

  //   if (error && error.message === 'password_incorrect') {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       oldPass: { hasMessage: 'Old password is incorrect', hasError: true },
  //     });
  //   }

  //   if (newPassword && newPassword?.length < 8) {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       newPass: {
  //         hasMessage: 'Password must be at least 12 characters',
  //         hasError: true,
  //       },
  //     });
  //   }

  //   const complexityRegex =
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  //   if (newPassword && !complexityRegex.test(newPassword)) {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       newPass: {
  //         hasMessage:
  //           'The password must include uppercase, lowercase, numbers, and special characters.',
  //         hasError: true,
  //       },
  //     });
  //   }

  //   // Check if new password and confirm password match
  //   if (newPassword !== rewritePassword) {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       rewritePassword: {
  //         hasMessage: 'Passwords do not match.',
  //         hasError: true,
  //       },
  //     });
  //   }

  //   return true;
  // };
  const handleChangePasswordUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleUpdateUserPasswor = () => {
    setConfirm((prev) => !prev);
    dispatch(updateUserPassworRequest(passworPayload));
    SetmodalShowEditProfile(false);
  };
  return (
    <div className="EditUser">
      <div className="user-wrapper fl_col gp10">
        <div className="header-Content">
          <TableHeader
            title={languageData.global.user.titleAddUser}
            type="save"
            textAdd={languageData.global.Modal.save}
            onChange={() => alert('')}
            labelSwitcher={languageData.global.status.actif}
            onClickDelete={() => alert('')}
            onClickSave={handleUpdateUser}
            disabled={isLoading}
            isClaimsValid
            fichShow
          />
        </div>

        <div className="body-Content">
          <div className="fl_col gp12">
            <CardContainer type={'form'}>
              <ProfileUserUpdate
                onChange={handleChange}
                userProfile={userProfile as IupdateUser}
              />
            </CardContainer>
            <CardContainer ClassName="change-user-pw" type={'form'}>
              <div className="header-block">
                <p className="p16  w-600 Nevada-txt pd22-t-b">
                  {languageData.global.user.Motdepasse}
                </p>
              </div>
              <div
                className="btn-pw fl_row ai_c gp10"
                onClick={() => SetmodalShowEditProfile(true)}
              >
                <p className="p15 w-600">{languageData.fr.user.Motdepasse_modifier}</p>
                <img src={EditGreyIcon} alt="" />
              </div>
              <Modal
                className="AddSubscriptionsModal"
                version="custom"
                title={'Modifier mot de passe'}
                visibility={modalShowEditProfile}
                close
                maxWidth={640}
                content={
                  <EditUserProfilePassword
                    errorMessage={ErrorMessage}
                    passwords={passwords}
                    confirm={confirm}
                    onClickCancel={() => SetmodalShowEditProfile(false)}
                    onClickSave={handleUpdateUserPasswor}
                    onChange={handleChangePasswordUpdate}
                  />
                }
                onClickClose={() => SetmodalShowEditProfile(false)}
              />
            </CardContainer>
          </div>
        </div>
        <Toast
          type="success"
          description="Your profile has been updated"
          isVisible={
            resetPasswordSucces !== undefined && resetPasswordSucces == true
          }
        />
        <Toast
          type="error"
          description="Your profile not updated"
          isVisible={error?.message === 'password_incorrect'}
        />
      </div>
    </div>
  );
}
