import React from 'react';
import FilterTableArrows from '../../assets/icons/FilterTableArrows.svg';
import RightArrowGreyIcon from '../../assets/icons/RightArrowGreyIcon.svg';
import BookPinkIcon from '../../assets/icons/BookPinkIcon.svg';
import EditItemIcon from '../../assets/icons/EditItemIcon.svg';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import etablissementIcon from '../../assets/icons/etablissementIcon.svg';
import EditButtonIconGreyDark from '../../assets/icons/EditButtonIconGreyDark.svg';
import EyePrimaryIcon from '../../assets/icons/EyePrimaryIcon.svg';
import FactureReglerIconPrimary from '../../assets/icons/FactureReglerIconPrimary.svg';
import MarketIconCirclePrimaryColor from '../../assets/icons/MarketIconCirclePrimaryColor.svg';
import NavitemLink from '../../assets/icons/NavitemLink.svg';
import ErrorIconCircleLightRed from '../../assets/icons/ErrorIconCircleLightRed.svg';
import { languageData } from '../../i18n';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import ToggleButton from '../Input/ToggleButton';
interface TdProps {
  value?: string | number | boolean;
  type:
    | 'filter'
    | 'button'
    | 'image'
    | 'text'
    | 'status'
    | 'empty'
    | 'nav'
    | 'rolePermission'
    | 'editUser'
    | 'userRole'
    | 'access'
    | 'editStatus'
    | 'ToggleButton'
    | 'statusPayment'
    | 'viewBill'
    | 'payBill'
    | 'reference'
    | 'edit-catalogue'
    | 'affectation'
    | 'linkMachine';
  status?:
    | 'active'
    | 'in_approval'
    | 'deactivated'
    | 'actif'
    | 'inactif'
    | 'inactive'
    | 'pending'
    | true
    | false;
  statusToggle?: 'inactive' | 'pending' | 'active';
  className?: string;
  img?: string;
  onClick?: () => void;
  onChange?: () => void;
  style?: 'bold';
  align?: 'left' | 'center' | 'right';
  flex?: 'flex-end' | 'center' | 'flex-start' | 'space-between';
  mobile?: boolean;
  maxWidth?: number;
  gap?: number;
  id?: string;
  center?: boolean;
  width?: number;
  onClickEditRole?: () => void;
  onClickDeleteRole?: () => void;
  onClickEditUser?: () => void;
  onClickDeleteUser?: () => void;
  onClickvalidate?: () => void;
  entrepriseSlot?: number;
  etablissementSlot?: number;
  roleColor?: string;
  roleName?: string[];
  roleBg?: string[];
  roleTextColors?: string[];
  restRolesSlot?: number;
  paid?: boolean;
  disableEdit?: boolean;
  affectationData?: serviceEtablissmentMachineTypeModels.listTypes;
  checked?: boolean;
  errorState?: boolean;
  loadingState?: boolean;
  stripeStatuts?: 'open' | 'draft' | 'paid' | 'unpaid';
}
const Td: React.FC<TdProps> = ({
  value,
  type,
  className,
  img,
  onClick,
  onChange,
  status,
  style,
  align,
  flex,
  gap,
  id,
  maxWidth,
  width,
  center,
  mobile,
  onClickEditRole,
  onClickDeleteRole,
  onClickEditUser,
  onClickDeleteUser,
  entrepriseSlot,
  etablissementSlot,
  roleColor,
  roleName,
  roleBg,
  roleTextColors,
  restRolesSlot,
  paid,
  disableEdit,
  affectationData,
  checked,
  statusToggle,
  onClickvalidate,
  errorState,
  loadingState,
  stripeStatuts,
}) => {
  // const getStatusText = () => {
  //   if (status === 'active') {
  //     return <span>{languageData.global.status.Active}</span>;
  //   } else if (status === 'in_approval') {
  //     return <span>{languageData.global.status.inApproval}</span>;
  //   } else {
  //     return <span>{languageData.global.status.deactivated}</span>;
  //   }
  // };

  const getStatusText = (value: string) => {
    switch (value) {
      case 'draft':
        return <span>{languageData.fr.statusFacture.draft}</span>;
      case 'open':
        return <span>{languageData.fr.statusFacture.open}</span>;
      case 'paid':
        return <span>{languageData.fr.statusFacture.paid}</span>;
      case 'unpaid':
        return <span>{languageData.fr.statusFacture.unpaid}</span>;
      default:
        return <span>{status}</span>;
    }
  };

  const getRoleStatusText = () => {
    switch (value) {
      case 'active':
      case true:
        return (
          <span style={{ color: 'green' }}>
            {languageData.global.status.Active}
          </span>
        );
        break;
      case 'deactivated':
      case false:
        return (
          <span style={{ color: '#475467' }}>
            {languageData.global.status.deactivated}
          </span>
        );
        break;

      case 'actif':
        return (
          <span style={{ color: 'green' }}>
            {languageData.global.status.actif}
          </span>
        );
        break;

      case 'inactif':
        return (
          <span style={{ color: '#475467' }}>
            {languageData.global.status.inactif}
          </span>
        );
        break;
      case 'oui':
        return <span style={{ color: 'green' }}>{'Oui'}</span>;
        break;

      case 'non':
      case false:
        return <span style={{ color: '#475467' }}>{'Non'}</span>;
        break;
    }
  };

  // const UserRolesFunction = () => {
  //   roleName && roleBg && roleTextColors ? (
  //     roleName.map((name, index) => (
  //       <p
  //         key={index}
  //         className="userRole p14 w-600"
  //         style={{
  //           textAlign: align,
  //           maxWidth: maxWidth,
  //           margin: `${center ? '0 auto' : ''}`,
  //           backgroundColor: roleBg[index],
  //           color: roleTextColors[index],
  //           borderColor: roleBg[index],
  //           borderStyle: "solid",
  //           borderWidth: "1px",
  //         }}
  //       >
  //         {name}
  //       </p>
  //     ))
  //   ) : null
  // }

  const exportTotalLength = () => {
    if (roleName && roleBg && roleTextColors) {
      const totalLength = roleName.length;
      return totalLength;
    }
    return 0;
  };
  const renderContent = () => {
    switch (type) {
      case 'text':
        const ContentText = () => (
          <p
            style={{
              textAlign: align,
              maxWidth: maxWidth,
              margin: `${center ? '0 auto' : ''}`,
            }}
            className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
          >
            {value}
          </p>
        );
        return mobile ? (
          <div
            className={`${className ? className : ''} ${type} ${paid ? 'paid-bill' : 'unpaid-bill'} `}
          >
            {ContentText()}
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          >
            {ContentText()}
          </td>
        );
      case 'filter':
        const ContentFilter = () => (
          <div
            style={{ justifyContent: flex }}
            className="fl_row ai_c jc_c gp10"
          >
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>
            <img src={img || FilterTableArrows} alt="" onClick={onClick} />
          </div>
        );
        return mobile ? (
          <div className={`${className ? className : ''} ${type}`}>
            {ContentFilter()}
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          >
            {ContentFilter()}
          </td>
        );
      case 'status':
        const ContentStatus = () => (
          <p
            style={{
              borderColor: `${value === 'actif' || value === 'active' || value === 'oui' || value === true ? 'green' : '#475467'}`,
            }}
            className="p14 OxfordBlue02-txt w-500"
          >
            <div
              style={{
                backgroundColor: `${value === 'deactivated' || value === 'non' || value === 'inactif' || value === false ? '#D0D5DD' : ''}`,
              }}
              className="backdropStatus hide"
            ></div>
            {getRoleStatusText()}
          </p>
        );
        return mobile ? (
          <div
            className={`${className ? className : ''} ${status ? status : ''} ${type}`}
          >
            {ContentStatus()}
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${status ? status : ''} ${type}`}
          >
            {ContentStatus()}
          </td>
        );
      case 'image':
        const ContentImage = () => (
          <div style={{ justifyContent: flex }} className="fl_row ai_c gp16">
            {/* <img src={PicturePlaceHolder} alt="" /> */}
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>
          </div>
        );
        return mobile ? (
          <div className={`${className ? className : ''} ${type}`}>
            {ContentImage()}
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          >
            {ContentImage()}
          </td>
        );
      case 'empty':
        return mobile ? (
          <div className={`${className ? className : ''} ${type}`}></div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          ></td>
        );

      case 'nav':
        return mobile ? (
          <div
            className={`${className ? className : ''} ${type}`}
            onClick={onClick}
          >
            <img src={RightArrowGreyIcon} alt="" />
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          >
            <span className="clickRow" onClick={onClick}></span>
            <img src={RightArrowGreyIcon} alt="" />
          </td>
        );

      case 'button':
        return mobile ? (
          <div
            className={`  ${className ? className : ''} ${type}`}
            onClick={onClick}
          >
            <div className="btn-cat fl_row ai_c gp4 p14">
              <img src={BookPinkIcon} alt="" />
              Catalogue
            </div>
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`  ${className ? className : ''} ${type}`}
            onClick={onClick}
          >
            <div className="btn-cat fl_row ai_c gp4 p14">
              <img src={BookPinkIcon} alt="" />
              Catalogue
            </div>
          </td>
        );
      case 'rolePermission':
        return (
          <td width={width ? width + '%' : ''}>
            <div
              style={{ justifyContent: flex }}
              className="user-actions fl_row gp15 ai_c jc_fe"
            >
              {/* <div>
                {onClickDeleteRole && (
                  <img
                    className="c-p"
                    src={BinGreyIcon}
                    alt=""
                    onClick={onClickDeleteRole}
                  />
                )}
              </div> */}
              {!disableEdit && (
                <div>
                  {onClickEditRole && (
                    <img
                      className="c-p"
                      src={EditGreyIcon}
                      alt=""
                      onClick={onClickEditRole}
                    />
                  )}
                </div>
              )}
            </div>
          </td>
        );
      case 'editUser':
        return (
          <td width={width ? width + '%' : ''}>
            <div style={{ justifyContent: flex }} className="fl_row gp22 ">
              {onClickEditUser && (
                <img
                  className="c-p"
                  src={EditGreyIcon}
                  alt=""
                  onClick={onClickEditUser}
                />
              )}

              {onClickDeleteUser && (
                <img
                  className="c-p"
                  src={EditItemIcon}
                  alt=""
                  onClick={onClickDeleteUser}
                />
              )}
            </div>
          </td>
        );
      case 'editStatus':
        return (
          <td width={width ? width + '%' : ''}>
            <div style={{ justifyContent: flex }} className="fl_row gp22 ">
              <img
                className="c-p"
                src={EditButtonIconGreyDark}
                alt=""
                width={32}
                onClick={onClick}
              />
            </div>
          </td>
        );
      case 'access':
        return mobile ? (
          <div>
            <div style={{ justifyContent: flex }} className="fl_row gp10 ">
              {/* <div className="access-item fl_row gp5 pd5-r-l">
                  <img src={entrepriseIcon} alt="" width={12} />
                  <p className="p12 w-600">{entrepriseSlot || 0}</p>
                </div> */}
              <div className="access-item fl_row gp5 pd5-r-l">
                <img src={etablissementIcon} alt="" width={12} />
                <p className="p12 w-600">{etablissementSlot || 0}</p>
              </div>
            </div>
          </div>
        ) : (
          <td width={width ? width + '%' : ''}>
            <div style={{ justifyContent: flex }} className="fl_row gp22 ">
              {/* {entrepriseSlot && (
                <div className="access-item fl_row gp5 pd5-r-l">
                  <img src={entrepriseIcon} alt="" width={12} />
                  <p className="p12 w-600">{entrepriseSlot || 0}</p>
                </div>
              )} */}

              <div className="access-item fl_row gp5 pd5-r-l">
                <img src={etablissementIcon} alt="" width={12} />
                <p className="p12 w-600">{etablissementSlot || 0}</p>
              </div>
            </div>
          </td>
        );
      case 'ToggleButton':
        return (
          <td width={width ? width + '%' : ''}>
            <ToggleButton
              statusToggle={statusToggle}
              onClick={onClick}
              enablePopupActivation
              onClickvalidate={onClickvalidate}
              isLoading={loadingState}
              errorState={errorState}
            />
          </td>
        );
      case 'userRole':
        const totalLength = exportTotalLength();
        const UserRoleContent = () => {
          return (
            <div className="access-listing-roles fl_row gp5 ai_c jc fl_wrap">
              {roleName && roleBg && roleTextColors
                ? roleName.slice(0, 3).map((name, index) => (
                    <div
                      className="userRole "
                      key={index}
                      style={{
                        textAlign: align,
                        maxWidth: maxWidth,
                        margin: `${center ? '0 auto' : ''}`,
                        backgroundColor: roleBg[index],
                        color: roleTextColors[index],
                        borderColor: roleBg[index],
                        borderStyle: 'solid',
                        borderWidth: '1px',
                      }}
                    >
                      <p className="p12 w-600" title={name}>
                        {name}
                      </p>
                    </div>
                  ))
                : null}
              {totalLength ? (
                totalLength > 3 ? (
                  <div className="restRoles">
                    {/* <p>Total roles: {totalLength}</p> */}
                    <p className="p12 label-rest-roles">{totalLength - 3}</p>
                    <div className="showRolesRest hide">
                      <div className="restListing fl_col gp3">
                        <div className="listing-wrapper">
                          {roleName && roleBg && roleTextColors
                            ? roleName.slice(3).map((name, index) => (
                                <p
                                  title={name}
                                  key={index + 3}
                                  className=" p12 w-600"
                                >
                                  {name}
                                </p>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : (
                ''
              )}
              <div className="totalLength hide">
                <p className="p14">{totalLength} role(s)</p>
              </div>
            </div>
          );
        };
        return mobile ? (
          <div>{UserRoleContent()}</div>
        ) : (
          <td width={width ? width + '%' : ''}>{UserRoleContent()}</td>
        );

      case 'statusPayment':
        return mobile ? (
          <div>
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
                color: `${paid ? '#027A48' : '#B42318'}`,
                backgroundColor: `${paid ? '#ECFDF3' : '#FEF3F2'}`,
                border: `1px solid ${paid ? '#027a485c' : '#b423184d'}`,
              }}
              className={`p14 rd30 pd12-r-l pd5-t-b OxfordBlue02-txt ${style ? 'w-700' : 'w-500'}`}
            >
              {paid ? 'Payée' : 'Impayée'}
            </p>{' '}
          </div>
        ) : (
          <td width={width ? width + '%' : ''}>
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
                color: `${paid ? '#027A48' : '#B42318'}`,
                backgroundColor: `${paid ? '#ECFDF3' : '#FEF3F2'}`,
                border: `1px solid ${paid ? '#027a485c' : '#b423184d'}`,
              }}
              className={`p14 rd30 pd12-r-l pd5-t-b OxfordBlue02-txt ${style ? 'w-700' : 'w-500'}`}
            >
              {/* {paid ? 'Payée' : 'Impayée'} */}
              {stripeStatuts && getStatusText(stripeStatuts)}
            </p>{' '}
          </td>
        );
      case 'reference':
        return mobile ? (
          <div
            className={`${className ? className : ''} ${type} ${paid ? 'paid-bill' : 'unpaid-bill fl_row ai_c gp8'} `}
          >
            {paid ? '' : <img src={ErrorIconCircleLightRed} alt="" />}
            <p
              style={{
                color: paid ? '' : '#B42318',
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>
          </div>
        ) : (
          <td
            width={width ? width + '%' : ''}
            className={`${className ? className : ''} ${type}`}
          >
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>
          </td>
        );
      case 'payBill':
        return mobile ? (
          <div>
            <div
              style={{ justifyContent: flex }}
              className="fl_row payBill gp4 rd12 ai_c jc_c  c-p"
              onClick={onClick}
            >
              {paid ? (
                ''
              ) : (
                <img className="c-p" src={FactureReglerIconPrimary} alt="" />
              )}
            </div>
          </div>
        ) : (
          <td width={width ? width + '%' : ''}>
            <div
              style={{ justifyContent: flex }}
              className="fl_row payBill gp4 rd12 ai_c jc_c pd10-r-l c-p"
              onClick={onClick}
            >
              {paid ? (
                ''
              ) : (
                <img className="c-p" src={FactureReglerIconPrimary} alt="" />
              )}
            </div>
          </td>
        );

      case 'viewBill':
        return mobile ? (
          <div>
            <div style={{ justifyContent: flex }} className="fl_row  gp22 jc_c">
              <span className="viewBill-td" onClick={onClick}></span>
              <img className="c-p" src={EyePrimaryIcon} alt="" />
            </div>
          </div>
        ) : (
          <td width={width ? width + '%' : ''}>
            <div style={{ justifyContent: flex }} className="fl_row gp22 jc_c">
              <span className="viewBill-td" onClick={onClick}></span>
              <img className="c-p" src={EyePrimaryIcon} alt="" />
            </div>
          </td>
        );

      case 'edit-catalogue':
        return (
          <td width={width ? width + '%' : ''}>
            <div className="fl_row jc_fe">
              <img
                className="c-p z-3"
                src={MarketIconCirclePrimaryColor}
                alt=""
                onClick={onClick}
              />
            </div>
          </td>
        );

      case 'affectation':
        return (
          <td width={width ? width + '%' : ''}>
            <div className="AffectationData fl_row gp10 jc_c ">
              {affectationData && (
                <div className="affectationValue" key={affectationData?.id}>
                  <p className="p12 w-600">{affectationData?.designation}</p>
                </div>
              )}
            </div>
          </td>
        );

      case 'linkMachine':
        return (
          <td width={width ? width + '%' : ''}>
            <div onClick={onClick}>
              <img className="c-p" src={NavitemLink} alt="" />
            </div>
          </td>
        );

      default:
        return mobile ? (
          <div>
            {' '}
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>{' '}
          </div>
        ) : (
          <td width={width ? width + '%' : ''}>
            {' '}
            <p
              style={{
                textAlign: align,
                maxWidth: maxWidth,
                margin: `${center ? '0 auto' : ''}`,
              }}
              className={`p14 OxfordBlue02-txt ${style ? 'w-900' : 'w-500'}`}
            >
              {value}
            </p>{' '}
          </td>
        );
    }
  };
  return renderContent();
};

export default Td;
