import React, { useState } from 'react';
import SwitcherCheckbox from '../../../../components/Catalogue/SwitcherCheckbox';
import InputField from '../../../../components/Input/Input';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';
/**
 * ModeDePaiement component description.
 *
 * @ModeDePaiement
 *
 */

interface ModeDePaiementProps {
  title?: string;
}

const ModeDePaiement: React.FC<ModeDePaiementProps> = ({ title }) => {
  const [paymentOptions, setPaymentOptions] = useState({
    PaiementSurPlace: false,
    PaiementEnLigne: false,
  });
  const [ComptePlateforme, setComptePlateforme] = useState(false);
  const [SansComptePlateforme, setSansComptePlateforme] = useState(true);

  const handleSwitcherChange = (option: keyof typeof paymentOptions) => {
    setPaymentOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const handleSwitcherComptePlateforme = () => {
    setComptePlateforme((prevState) => {
      const newState = !prevState;
      // Automatically toggle SansComptePlateforme based on ComptePlateforme
      setSansComptePlateforme(!newState);
      return newState;
    });
  };

  return (
    <div className="ModeDePaiement tab-content-section">
      <div className="block-wrapper fl_col gp20">
        <div className="fl_row gp20">
          <SwitcherCheckbox
            onChange={() => handleSwitcherChange('PaiementSurPlace')}
            ischecked={paymentOptions.PaiementSurPlace}
            label="Paiement sur place"
          />
          <SwitcherCheckbox
            onChange={() => handleSwitcherChange('PaiementEnLigne')}
            ischecked={paymentOptions.PaiementEnLigne}
            label="Paiement en ligne"
          />
        </div>
        {paymentOptions.PaiementEnLigne && (
          <>
            <SwitcherCheckbox
              onChange={handleSwitcherComptePlateforme}
              ischecked={ComptePlateforme}
              label="Compte Plateforme"
            />
            {SansComptePlateforme && !ComptePlateforme && (
              <>
                <InputField
                  isRequired={true}
                  type="text"
                  name="secret_key"
                  placeholder="XXXXXXXXXXXXXXX"
                  label="Clé privé du service (secret key)"
                  style="form"
                />
                <InputField
                  isRequired={true}
                  type="text"
                  name="public_key"
                  placeholder="XXXXXXXXXXXXXXX"
                  label="Clé publique du service"
                  style="form"
                />
              </>
            )}

            {ComptePlateforme && (
              <>
                <InputField
                  isRequired={true}
                  type="text"
                  name="stripe_fees"
                  placeholder="XXXXXXXXXXXXXXX"
                  label="Frais de paiement Stripe"
                  style="form"
                />
              </>
            )}
          </>
        )}
        {paymentOptions.PaiementSurPlace && <></>}
      </div>
      <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div>
    </div>
  );
};

export default ModeDePaiement;
