import Td from '../../../components/Table/Td-table';
import Tr from '../../../components/Table/Tr-table';

type TdProps = {
  item: any;
  id: number;
  iDate: string;
  isInProcessing: boolean;
  reference: string;
  paymentDate: string;
  totalTTC: number;
  isPaid: boolean;
  convertDate: any;
  setShowValidatePayement: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setFacture?: React.Dispatch<React.SetStateAction<any>>;
  stripeStatuts?: 'open' | 'draft' | 'paid' | 'unpaid';
};

export default function DescktopTableBilling({
  item,
  id,
  reference,
  totalTTC,
  iDate,
  convertDate,
  paymentDate,
  isPaid,
  setShowValidatePayement,
  setShowInvoice,
  setFacture,
}: TdProps) {
  return (
    <Tr key={id}>
      <Td
        style="bold"
        type="text"
        value={reference !== null ? reference : 'N/A'}
        width={40}
        align="left"
      />
      <Td type="text" value={totalTTC} width={6} align="center" />
      <Td
        type="text"
        value={iDate !== null ? convertDate(iDate) : 'N/A'}
        width={6}
        align="center"
      />
      <Td
        type="text"
        value={paymentDate !== null ? convertDate(paymentDate) : '-'}
        width={6}
        align="center"
      />
      <Td
        type="statusPayment"
        style="bold"
        width={6}
        align="center"
        id="x001"
        paid={isPaid}
        stripeStatuts={item.stripeStatuts}
      />
      <Td
        type="payBill"
        width={2}
        onClick={() => setShowValidatePayement(true)}
        paid
      />
      <Td
        type="viewBill"
        width={2}
        onClick={() => {
          setShowInvoice(true);
          setFacture && setFacture(item);
        }}
      />
    </Tr>
  );
}
