import React, { useEffect, useState } from 'react';
import TableHeader from '../../components/Table/TableHeader';
import { languageData } from '../../i18n';
import Table from '../../components/Table/Table';
import Tbody from '../../components/Table/Tbody-table';
import Td from '../../components/Table/Td-table';
import Thead from '../../components/Table/Thead-table';
import Tr from '../../components/Table/Tr-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  newRoleChanged,
  getRolesListAction,
  startGetRoleById,
} from '../../store/actions/actions';
import Pagination from '../../components/Table/Pagination-table';
import { usePagination } from '../../pages/etablissement/pages/usePagination';
import tabs_list from '../../data/tabsList';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { OpenTab } from '../../services/tabs/OpenTab';
import PageTable from '../Table/PageTable';
import TableSection from '../Table/TableSection';
import TableContent from '../Table/TableContent';
import DesktopTableContainer from '../Table/DesktopTableContainer';
import TableHeaderRole from '../../components/Table/TableHeaderRole';

export default function RoleslistContainer() {
  const { roles, totalPagesNumbers } = useSelector((state: any) => ({
    roles: state.role.roles,
    totalPagesNumbers: state.role.totalPagesNumbers,
  }));
  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const claimsUser = useSelector((state: any) => state.auth?.claims);
  const claimsRolesUsers = useSelector(
    (state: any) => state.auth?.claimsRolesUsers,
  );
  const isAdmin = useSelector((state: any) => state.auth?.isAdmin);
  const paginationConfig = React.useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );
  const {
    currentPage,
    itemsPerPage,
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  } = usePagination(paginationConfig);

  const pagination = React.useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );

  const fetchRoles = React.useCallback(() => {
    dispatch(getRolesListAction(pagination));
  }, [dispatch, pagination]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const handleClickOpenAddRole = () => {
    OpenTab({
      label: tabs_list[7].name,
      tabs: open_tabs,
      dispatch,
    });
  };

  const handleClickOpenEtablissementDetails = (id: string, name: string) => {
    OpenTab({
      key: tabs_list[7].tabContent.key,
      label: tabs_list[7].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
    });
    dispatch(startGetRoleById(id));
  };

  return (
    <PageTable className="RoleslistContainer">
      {/* <div className="container-wrapper fl_col gp10 h-full"> */}
      <TableSection>
        <TableHeaderRole
          type="add"
          title={'Rôles(s)'}
          textAdd={'Nouveau'}
          onClickAdd={() => handleClickOpenAddRole()}
          claimsUser={claimsUser?.includes('FioManagerMobile.USERS')}
          claimsRolesUsers={claimsRolesUsers?.includes('Roles.Ajout')}
          isAdmin={isAdmin}
          filteredAddButton
        />
        <TableContent className="TableUserRoles">
          <DesktopTableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Td type="text" value="Intitulé" width={80} align="left" />
                  <Td
                    type="filter"
                    value="Status"
                    width={10}
                    align="center"
                    flex="center"
                  />
                  <Td type="empty" width={10} align="center" flex="center" />
                </Tr>
              </Thead>
              <Tbody>
                {roles?.map((role: serviceTabsModels.IRole) => {
                  return (
                    <Tr key={role.id}>
                      <Td
                        className="role-name"
                        type="text"
                        value={role.name}
                        width={80}
                        align="left"
                      />
                      <Td
                        type="status"
                        value={role.active}
                        width={10}
                        align="center"
                        flex="center"
                      />
                      {claimsRolesUsers?.includes('Roles.Modification') && (
                        <Td
                          type="rolePermission"
                          width={10}
                          onClickEditRole={() =>
                            handleClickOpenEtablissementDetails(
                              role?.id,
                              role.name,
                            )
                          }
                          flex="center"
                          disableEdit={role.isDefault}
                        />
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </DesktopTableContainer>
        </TableContent>
      </TableSection>
      <Pagination
        totalPagesNumbers={totalPagesNumbers}
        nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleNextClick(currentPage)
        }
        prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handlePreviousClick(currentPage)
        }
        currentPage={currentPage}
        totalPages={totalPagesNumbers}
        handlePageChange={handlePageChange}
      />
      {/* </div> */}
    </PageTable>
  );
}
