import React, { ReactNode } from 'react';

/**
 * DesktopTableContainer component description.
 *
 * @DesktopTableContainer
 *
 */

interface DesktopTableContainerProps {
  children: ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

const DesktopTableContainer: React.FC<DesktopTableContainerProps> = ({
  children,
  className,
  ref
}) => {
  return (
    <div className={`DesktopTableContainer fl_col gp10 h-full ${className}`} ref={ref}>
      {children}
    </div>
  );
};

export default DesktopTableContainer;