import React, { ReactNode } from 'react';
import SkeletonLoader from '../SkeletonLoader';

interface Table {
  children?: ReactNode;
  className?: string;
  showSkeleton?: boolean;
  ref?: any
}

const Table: React.FC<Table> = ({ children, className, showSkeleton, ref }) => {
  return showSkeleton ? (
    <SkeletonLoader show type="table" />
  ) : (
    <table
      className={`TableContainer Table ${className ? className : ''}`}
      ref={ref}
    >
      {children}
    </table>
  );
};

export default Table;
