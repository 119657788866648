import React from 'react';
import TimerSelect from './TimerSelect';
import ButtonElement from '../../../../components/button/button';
import addBtnFullBgBleu from '../../../../assets/icons/addBtnFullBgBleu.svg';
import TrashIconRed from '../../../../assets/icons/TrashIconRed.svg';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';

/**
 * PlageHoraire component description.
 *
 * @PlageHoraire
 *
 */

const PlageHoraire: React.FC = () => {
  return (
    <div className="PlageHoraire tab-content-section">
      <div className="fl_col">
        <div className="repeater-wrapper fl_col gp20">
          <div className="block-wrapper fl_col gp20">
            <div className="time-repeater rd12">
              <div className="date-picker fl_row gp10">
                <div className="date-row fl-1">
                  <input type="checkbox" name="lundi" id="lundi-checkbox" />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="lundi-checkbox"
                    >
                      Lundi
                    </label>
                  </div>
                  <span className="next-day p12 FunGreen-txt w-700">
                    Jusqu`à 03:00
                  </span>
                </div>

                <div className="date-row fl-1">
                  <input type="checkbox" name="mardi" id="mardi-checkbox" />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="mardi-checkbox"
                    >
                      Mardi
                    </label>
                  </div>
                </div>

                <div className="date-row fl-1">
                  <input
                    type="checkbox"
                    name="mercredi"
                    id="mercredi-checkbox"
                  />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="mercredi-checkbox"
                    >
                      Mercredi
                    </label>
                  </div>
                </div>

                <div className="date-row fl-1">
                  <input type="checkbox" name="jeudi" id="jeudi-checkbox" />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="jeudi-checkbox"
                    >
                      Jeudi
                    </label>
                    <span className="next-day p12 FunGreen-txt w-700">
                      Jusqu`à 03:00
                    </span>
                  </div>
                </div>

                <div className="date-row fl-1">
                  <input
                    type="checkbox"
                    name="vendredi"
                    id="vendredi-checkbox"
                  />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="vendredi-checkbox"
                    >
                      Vendredi
                    </label>
                  </div>
                </div>

                <div className="date-row fl-1">
                  <input type="checkbox" name="samedi" id="samedi-checkbox" />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="samedi-checkbox"
                    >
                      Samedi
                    </label>
                  </div>
                </div>

                <div className="date-row fl-1">
                  <input
                    type="checkbox"
                    name="dimanche"
                    id="dimanche-checkbox"
                  />
                  <div className="day-name">
                    <label
                      className="date-name p18 w-500"
                      htmlFor="dimanche-checkbox"
                    >
                      Dimanche
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="timer-setter fl_row jc_s ai_e">
              <div className="row gp30 ai_e">
                <div className="timer-field fl_col gp8">
                  <p className="p18 w-500">De</p>
                  <TimerSelect></TimerSelect>
                </div>
                <div className="timer-field fl_col gp8">
                  <p className="p18 w-500 ">Jusqu’a</p>
                  <TimerSelect></TimerSelect>
                </div>
                <span className="p16 pd10-t-b">(+1 jour)</span>
              </div>

              <ButtonElement
                buttonStyle="btn-red-light"
                type={'IconButton'}
                text={'Supprimer'}
                buttonSize={'large'}
                onClick={() => alert('')}
                icon={TrashIconRed}
                reverse
              />
            </div>
          </div>
        </div>
        <div className="fl_row jc_fe pd20-r-l">
          <button className="add-repeater p16 fl_row ai_c jc_s w-500">
            <img src={addBtnFullBgBleu} alt="" />
            Ajouter
          </button>
        </div>
      </div>
      <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div>
    </div>
  );
};

export default PlageHoraire;
