import { ChangeEvent, useEffect, useMemo, useState, useRef } from 'react';
import { languageData } from '../../../i18n';
import TableRightArrow from '../../../assets/icons/TableRightArrowPrimaryColor.svg';
import TableArrowDropDownGrey from '../../../assets/icons/TableArrowDropDownGrey.svg';
import PhoneIconLightDark from '../../../assets/icons/PhoneIconLightDark.svg';
import EmailIconBlack from '../../../assets/icons/EmailIconBlack.svg';
import etablissementIcon from '../../../assets/icons/etablissementIcon.svg';
import Pagination from '../../../components/Table/Pagination-table';
import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import MobileTableContainer from '../../../container/Table/MobileTableContainer';
import MobileTableCard from '../../../components/Table/MobileTableCard';
import TableHeader from '../../../components/Table/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListFilterEtablissementsRequest,
} from '../../../store/actions/actions';
import { debounce } from 'lodash';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import { OpenTab } from '../../../services/tabs/OpenTab';
import tabs_list from '../../../data/tabsList';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import PageTable from '../../Table/PageTable';
import TableSection from '../../Table/TableSection';
import TableContent from '../../Table/TableContent';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import { usePagination } from '../../../pages/etablissement/pages/usePagination';

type ExpandedCardState = {
  [key: string]: boolean;
};
export default function ListeDesEtablissementContainer() {
  const [conbineSearch, setCombineSearch] =
    useState<serviceEtablissmentMachineTypeModels.Body>({
      name_PhoneNumber: '',
      companyId: [],
      status_CompanyId: null,
    });


  const dispatch = useDispatch();
  const { Filteredetablissements } = useSelector(
    (state: {
      Filteredetablissements: {
        Filteredetablissements: serviceEtablissmentMachineTypeModels.Filteredetablissements;
      };
    }) => state.Filteredetablissements,
  );

  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const companies_list = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.entreprises,
  );

  const getListStatutsEsta = useSelector(
    (state: {
      getListStatutsEsta: {
        getListStatutsEst: serviceEtablissmentMachineTypeModels.getList[];
      };
    }) => state.getListStatutsEsta,
  );
  const paginationConfig = useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );
  const {
    currentPage,
    itemsPerPage,
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  } = usePagination(paginationConfig);

  const pagination = useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );

  useEffect(() => {
    const payload = {
      page: conbineSearch?.name_PhoneNumber
        ? 0
        : pagination.paginationModel.page,
      pageSize: conbineSearch?.name_PhoneNumber
        ? 0
        : pagination.paginationModel.pageSize,
      body: {
        ...(conbineSearch?.name_PhoneNumber ||
        conbineSearch?.companyId ||
        conbineSearch?.status_CompanyId
          ? {
              name_PhoneNumber: conbineSearch?.name_PhoneNumber ?? '',
              companyId: conbineSearch?.companyId,
              status_CompanyId: conbineSearch?.status_CompanyId,
            }
          : {}),
      },
    } as serviceEtablissmentMachineTypeModels.PayloadEtablissement;

    dispatch(getListFilterEtablissementsRequest(payload));
  }, [conbineSearch, pagination]);

  const handleClickOpenEtablissementDetails = (id: number, name: string) => {
    OpenTab({
      key: tabs_list[5].tabContent.key,
      label: tabs_list[5].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
      companyId: '' + id,
      isEtablishement: true,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue =
      name === 'status_CompanyId' && value === '3' ? null : value;
    setCombineSearch((prev) => ({ ...prev, [name]: newValue }));
  };
  const debouncedHandleChange = debounce(handleChange, 300);

  const [expandedCard, setExpandedCard] = useState<ExpandedCardState>({});
  const toggleExpand = (userId: string) => {
    setExpandedCard({ [userId]: !expandedCard[userId] });
  };
  return (
    <PageTable className="ListeDesEtablissementContainer">
      <TableSection>
        <TableHeader
          type="filter"
          title={languageData.fr.MenuLabel.etablissement}
          onChange={debouncedHandleChange}
          setCombineSearch={setCombineSearch}
          status={getListStatutsEsta.getListStatutsEst}
          companies_list={companies_list}
        />
      
          <TableContent>
            <DesktopTableContainer>
              <Table className="Table TableEtablissement rd12">
                <Thead>
                  <Tr>
                    <Td
                      type="text"
                      value={languageData.fr.TabsTitle.Établissement}
                    />
                    <Td
                      type="text"
                      value={languageData.fr.TableHeaders.status}
                    />
                    <Td
                      type="text"
                      value={languageData.fr.MenuLabel.entreprises}
                    />
                    <Td
                      type="text"
                      className="mobile-number"
                      value={languageData.fr.mobile}
                    />
                    <Td type="text" value={languageData.fr.email_address} />
                    <Td type="empty" />
                  </Tr>
                </Thead>
                <Tbody>
                  {Filteredetablissements?.result?.map(
                    (
                      result: serviceEtablissmentMachineTypeModels.IResultEtablissment,
                    ) => (
                      <Tr key={result.id}>
                        <Td type="image" value={result?.commercial_Name} />
                        <Td type="status" value={result?.status?.name} />
                        <Td type="text" value={result?.company} />
                        <Td
                          type="text"
                          className="mobile-number"
                          value={result?.mobile_telephone_number}
                        />
                        <Td type="text" value={result?.email} />
                        <Td
                          type="nav"
                          onClick={() =>
                            handleClickOpenEtablissementDetails(
                              result.id,
                              result.commercial_Name,
                            )
                          }
                        />
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            </DesktopTableContainer>

            <MobileTableContainer>
              {Filteredetablissements?.result?.map(
                (
                  result: serviceEtablissmentMachineTypeModels.IResultEtablissment,
                ) => (
                  <MobileTableCard
                    expand={expandedCard[result.id] || false}
                    key={result.id}
                  >
                    <div className="table-header fl_row ai_c jc_s">
                      <div className="left-side fl_row gp15 ai_c">
                        <img
                          className="arrow-expend"
                          src={TableArrowDropDownGrey}
                          alt=""
                          onClick={() => toggleExpand(result.id.toString())}
                          loading="lazy"
                        />
                        <div className="main-informations fl_col gp10">
                          <Td
                            className="mx1"
                            type="text"
                            value={result?.commercial_Name}
                            mobile
                          />
                        </div>
                        <span
                          className="card-click"
                          onClick={() =>
                            handleClickOpenEtablissementDetails(
                              result.id,
                              result.commercial_Name,
                            )
                          }
                        ></span>
                      </div>
                      <div className="right-side fl_row gp15 ai_c">
                        <Td
                          type="status"
                          value={result?.status?.isActive}
                          mobile
                        />
                        <img
                          src={TableRightArrow}
                          alt=""
                          loading="lazy"
                          onClick={() =>
                            handleClickOpenEtablissementDetails(
                              result.id,
                              result.commercial_Name,
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="table-body fl_col gp10">
                      <div className="tbody-col fl_row gp10">
                        <div className="label">
                          <div className="iconlabel">
                            <img src={etablissementIcon} alt="" />
                          </div>
                        </div>
                        <Td type="text" value={result.company} mobile />
                      </div>
                      {result.mobile_telephone_number && (
                        <div className="tbody-col fl_row gp10">
                          <div className="label">
                            <div className="iconlabel">
                              <img src={PhoneIconLightDark} alt="" />
                            </div>
                          </div>

                          <Td
                            className="ValueMobileNumber"
                            type="text"
                            value={result.mobile_telephone_number}
                            mobile
                          />
                        </div>
                      )}
                      {result.email && (
                        <div className="tbody-col fl_row gp10">
                          <div className="label">
                            <div className="iconlabel">
                              <img src={EmailIconBlack} alt="" />
                            </div>
                          </div>
                          <Td
                            className="ValueEmail"
                            type="text"
                            value={result.email}
                            mobile
                          />
                        </div>
                      )}
                    </div>
                  </MobileTableCard>
                ),
              )}
            </MobileTableContainer>
          </TableContent>
      </TableSection>
      <Pagination
        totalPagesNumbers={Filteredetablissements?.pagination?.nbPage}
        nextpageClick={() => handleNextClick(currentPage)}
        prevpageClick={() => handlePreviousClick(currentPage)}
        currentPage={currentPage}
        totalPages={Filteredetablissements?.pagination?.nbPage}
        handlePageChange={handlePageChange}
      />
    </PageTable>
  );
}
